export {}

declare global {
  interface Array<T> {
    unique(): Array<T>
    last(): T
  }
}

Array.prototype.unique = function <T>(): Array<T> {
  return Array.from(new Set<T>(this))
}

Array.prototype.last = function <T>(): T {
  return this[this.length - 1]
}
