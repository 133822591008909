import { formatDateYYYYMMDD, formatTime } from '@/helper/date'

export {}

declare global {
  interface Date {
    formatDateYYYYMMDD(): string
    formatTime(): string
  }
}

Date.prototype.formatDateYYYYMMDD = function (): string {
  return formatDateYYYYMMDD(this)
}

Date.prototype.formatTime = function (): string {
  return formatTime(this)
}
