import { Tables } from '@/types/database'
import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { supabase } from '@/supabase'
import { useAuthStore } from './auth'
import { UserProperty } from '@/types/extendDatabase'

const defaultSetting: Ref<Tables<'user_properties'>> = ref({
  id: 0,
  label: 'default',
  value_bool: false,
  value_int: 0,
  value_string: '',
  value_float: 0,
  creator: null,
  value_int_array: [],
})

export const useUserSettingsStore = defineStore(
  'userSettings',
  () => {
    const center = ref([7.77, 49.45])
    const settings: Ref<Tables<'user_properties'>[]> = ref([])

    const authStore = useAuthStore()

    const user = computed(() => authStore.user)
    async function fetchSettings() {
      if (!user.value) {
        settings.value = []
        return
      }
      try {
        const { data, error } = await supabase.from('user_properties').select('*')
        if (error) throw error
        settings.value = data
      } catch (error) {
        console.error(error)
      }
    }

    async function updateSetting(label: UserProperty, update: Partial<Tables<'user_properties'>>) {
      if (!user.value) return
      const setting = settings.value.find((s) => s.label === label && s.creator === user.value!.id)
      try {
        if (setting) {
          const { data, error } = await supabase
            .from('user_properties')
            .update(update)
            .eq('id', setting.id)
            .select('*')
            .single()
          if (error) throw error
          const index = settings.value.findIndex((s) => s.id === setting.id)
          settings.value[index] = data
        } else {
          const { data, error } = await supabase
            .from('user_properties')
            .insert({ ...update, label })
            .select('*')
            .single()
          if (error) throw error
          settings.value.push(data)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const getSetting = (label: UserProperty) => {
      const sets = settings.value.filter((s) => s.label === label)
      if (sets.length === 0) return defaultSetting.value
      if (sets.length === 1) return sets[0]
      else return sets.find((s) => s.creator !== null)!
    }

    const getSettingValue = (label: UserProperty) => {
      const setting = getSetting(label)
      return setting.value_bool!
    }

    return { center, settings, updateSetting, fetchSettings, getSettingValue }
  },
  {
    persist: true,
  }
)
