export const IGNITION_OFF = 0
export const IGNITION_ON = 1
export const IGNITION_ON_AND_MOVEMENT = 2

// could be fetched from DB - needed commit
export const propertyTypeDataTypes: string[] = []
propertyTypeDataTypes[1] = 'INTEGER'
propertyTypeDataTypes[2] = 'FLOAT'
propertyTypeDataTypes[3] = 'STRING'
propertyTypeDataTypes[4] = 'TEXT'
propertyTypeDataTypes[5] = 'DATE'
propertyTypeDataTypes[6] = 'SELECT'
propertyTypeDataTypes[7] = 'BOOLEAN'
propertyTypeDataTypes[8] = 'SERVICE_DATE'
propertyTypeDataTypes[9] = 'RADIO_BUTTON'
propertyTypeDataTypes[10] = 'AUTOCOMPLETE'
propertyTypeDataTypes[11] = 'MULTISELECT'

export const devicePropertyManufacturerTpyeId = 1000
export const devicePropertyTypeDesignationTpyeId = 1001
export const devicePropertyCommentTpyeId = 1002
export const devicePropertyMountTypeId = 1003
export const devicePropertiesWithFixedPositions = [
  devicePropertyManufacturerTpyeId,
  devicePropertyTypeDesignationTpyeId,
  devicePropertyCommentTpyeId,
]
export const hideReplayTypeId = 3002
